import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';

const App = () => {
  const [currentSection, setCurrentSection] = useState('home');

  return (
    <ParallaxProvider>
      <div className="bg-black text-white min-h-screen font-sans antialiased overflow-hidden">
        <StarField />
        <main>
          <Home setCurrentSection={setCurrentSection} />
        </main>
        <SocialLinks />
      </div>
    </ParallaxProvider>
  );
};

const StarField = () => {
  return (
    <div className="fixed inset-0 z-0">
      <Canvas camera={{ position: [0, 0, 1] }}>
        <Stars />
      </Canvas>
    </div>
  );
};

function Stars(props) {
  const ref = React.useRef();
  const [sphere] = useState(() => random.inSphere(new Float32Array(5000), { radius: 1.5 }));

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 30;
    ref.current.rotation.y -= delta / 45;
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial
          transparent
          color="#ffffff"
          size={0.002}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}

const Home = ({ setCurrentSection }) => {
  useEffect(() => setCurrentSection('home'), [setCurrentSection]);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut" }
    });
  }, [controls]);

  return (
    <section id="home" className="relative flex items-center justify-center h-screen overflow-hidden">
      <Parallax className="relative z-10 text-center" y={[-20, 20]} tagOuter="figure">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={controls}
          className="text-7xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-100 via-purple-200 to-purple-300"
        >
          Semih Tüzün
        </motion.h1>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 1 }}
        >
          <TypeAnimation
            sequence={[
              'Computer Engineer',
              2000,
              'Developer',
              2000,
              'Programmer',
              2000,
              'Software Engineer',
              2000,
            ]}
            wrapper="h2"
            repeat={Infinity}
            className="text-3xl text-gray-300 font-light"
          />
        </motion.div>
      </Parallax>
    </section>
  );
};

const SocialLinks = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.3 + 1, duration: 1, ease: "easeOut" }
    }));
  }, [controls]);

  return (
    <motion.div 
      className="fixed bottom-10 left-0 right-0 flex justify-center items-center space-x-8 z-20"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 1, duration: 1, ease: "easeOut" }}
    >
      {[
        { icon: FaGithub, link: "https://github.com/stuzun687" },
        { icon: FaLinkedin, link: "https://linkedin.com/in/semih-tüzün-a790592b5/" },
        { icon: FaEnvelope, link: "mailto:stuzun687@gmail.com" }
      ].map((social, index) => (
        <SocialIcon key={index} icon={social.icon} link={social.link} controls={controls} custom={index} />
      ))}
    </motion.div>
  );
};

const SocialIcon = ({ icon: Icon, link, controls, custom }) => (
  <motion.a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
    whileHover={{ scale: 1.2, rotate: 5, transition: { duration: 0.3 } }}
    whileTap={{ scale: 0.9, transition: { duration: 0.3 } }}
    initial={{ opacity: 0, y: 20 }}
    animate={controls}
    custom={custom}
  >
    <Icon size={28} />
  </motion.a>
);

export default App;